import React, { useEffect } from "react";
import webImg from "../Assests/web-services.svg";
import mobileImg from "../Assests/mobile-services.svg";
import hostingImg from "../Assests/hosting-services.svg";
import ecommerceImg from "../Assests/ecommerce-services.svg";
import digitalImg from "../Assests/digital-services.svg";
import graphicsImg from "../Assests/graphics-services.svg";
import erpImg from "../Assests/erp-services.svg";
import itImg from "../Assests/it-services.svg";
import aboutImg from "../Assests/about-img.png";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <div className="container-lg py-5 d-none d-sm-block">
      <div className='p-banner'>
      <h1>Our Services</h1>
    </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">WEB DEVELOPMENT</p>
              <p className="mb-0">
                We specialize in creating and optimizing high-quality, custom
                websites for businesses and organizations of all sizes. Building
                mobile-friendly and easy-to-use websites and applications for
                clients.Our team excels in crafting visually appealing websites with seamless navigation, ensuring a user-friendly experience across devices.From responsive design to robust e-commerce platforms, our services encompass a wide spectrum. Embracing the latest technologies, we deliver cutting-edge solutions using PHP,Reactjs,Angular and more and more.  Engage your audience with captivating interfaces and dynamic web applications that leave a lasting impression. 
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <div className="about-image my-5">
              <img height={250} width="100%" src={webImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">MOBILE APP DEVELOPMENT</p>
              <p className="mb-0">
                We develop high-quality, custom cross-platform mobile
                applications that are robust and optimized for performance,
                scalability, and accessibility.
                Step into the future of mobile technology with our premium mobile app development services.  Our team ensures that your mobile app is not just functional but also optimized for top-notch performance, scalability, and accessibility on various devices. Embracing the latest technologies, we guarantee robust and reliable solutions using frameworks like React Native and Flutter. Our development process is agile, ensuring timely delivery without compromising on quality
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first">
            <div className="about-image my-5">
              <img height={250} width="100%" src={mobileImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">DOMAIN & HOSTING SERVICES</p>
              <p className="mb-0">
                We provide domain registration and web hosting services to
                individuals and organizations to enable them gain visibility in
                the digital space.
                Our hosting services include advanced security features, regular backups, and 24/7 technical support, providing peace of mind for website owners. With a focus on user satisfaction, we offer intuitive control panels for easy management of domains, email accounts, and other hosting settings. Stay ahead in the competitive digital landscape with our hosting services that prioritize uptime and performance. As your digital partner, we provide a foundation for success in the online realm, allowing you to concentrate on what matters – your content and business goals
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <div className="about-image my-5">
              <img height={250} width="100%" src={hostingImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">E-COMMERCE</p>
              <p className="mb-0">
                E-commerce website is no longer limited to just adding shopping
                cart! Now developing an ideal e-commerce website seeks
                integrated front and back end support for numerous reasons.
                These include facilitating your employees to track orders, gain
                information about customers and easy update on every page of
                website.
               Our e-commerce solutions seamlessly integrate front and back-end functionalities, providing a comprehensive platform for your business. From intuitive shopping carts to a robust inventory management system, our websites empower your employees to efficiently track orders, gather customer insights, and make real-time updates across every page. 
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first">
            <div className="about-image my-5">
              <img height={250} width="100%" src={ecommerceImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">DIGITAL MARKETING</p>
              <p className="mb-0">
                Our IT consultations service, Digital marketing involves
                marketing to consumers through digital channels, including
                websites, mobile devices, and social media platforms.
                We specialize in leveraging the vast landscape of online channels to strategically connect your brand with the right audience. From impactful website optimization and targeted social media campaigns to email marketing and search engine optimization (SEO), our strategies are designed to enhance your online visibility and drive meaningful engagement.As your digital partners, we navigate the ever-evolving digital landscape to keep your brand at the forefront of industry trends. Elevate your marketing efforts with our IT consultations and digital marketing services

              </p>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <div className="about-image my-5">
              <img height={250} width="100%" src={digitalImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">GRAPHICS DESIGN</p>
              <p className="mb-0">
                Our IT consultations service provides design of different types
                like Brands Identity and Logo Design,Packaging Design, Web And
                Mobile Design, Layout and Print Design etc.
                Elevate your brand aesthetics with our versatile graphic design services through our IT consultations. We specialize in crafting visually compelling elements that leave a lasting impact across various mediums. From creating a unique brand identity and logo design that resonates with your audience to designing eye-catching packaging that stands out on shelves, our expertise extends to web and mobile design, ensuring a seamless and visually appealing online presence.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first">
            <div className="about-image my-5">
              <img height={250} width="100%" src={graphicsImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">ERP</p>
              <p className="mb-0">
                Our IT consultations service provides expert guidance and
                support for businesses and individuals looking to optimize their
                technology solutions.
                Delve into the future of business efficiency with our IT consultations and expert ERP (Enterprise Resource Planning) solutions. We offer comprehensive guidance and support for businesses and individuals seeking to streamline their operations through cutting-edge technology.<br/> Our ERP solutions cover a spectrum of functionalities, including finance, human resources, supply chain, and customer relationship management.
                Partner with us to revolutionize your business processes through our IT consultations and ERP solutions. Unlock the efficiency, transparency, and scalability that ERP brings, empowering your organization for sustained growth in today's competitive landscape.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <div className="about-image my-5">
              <img height={250} width="100%" src={erpImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-6 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">IT CONSULTATIONS</p>
              <p className="mb-0">
                Our IT consultations service provides expert guidance and
                support for businesses and individuals looking to optimize their
                technology solutions.
                Our IT consultations service serves as your trusted partner in navigating the complex landscape of technology solutions. We offer tailored guidance and support to businesses and individuals seeking to optimize their technological infrastructure. Our expert consultants delve into your specific needs, providing strategic advice on selecting, implementing, and managing the right IT solutions. our IT consultations delve into risk management, compliance, and data privacy, ensuring that your IT infrastructure meets regulatory requirements and safeguards sensitive information. 
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first">
            <div className="about-image my-5">
              <img height={250} width="100%" src={itImg} alt="..." />
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg py-5 d-block d-sm-none">
        <div className="about mb-5">
          <h3 className="fw-bold">Our Services</h3>
        </div>
        <div className="container-lg mb-5">
          <div className="about-img text-center">
            <img width="75%" src={aboutImg} alt="..." />
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">WEB DEVELOPMENT</p>
              <p className="mb-0">
                We specialize in creating and optimizing high-quality, custom
                websites for businesses and organizations of all sizes. Building
                mobile-friendly and easy-to-use websites and applications for
                clients.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 d-none">
            <div className="about-image">
              <img height={250} width="100%" src={webImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">MOBILE APP DEVELOPMENT</p>
              <p className="mb-0">
                We develop high-quality, custom cross-platform mobile
                applications that are robust and optimized for performance,
                scalability, and accessibility.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={mobileImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">DOMAIN & HOSTING SERVICES</p>
              <p className="mb-0">
                We provide domain registration and web hosting services to
                individuals and organizations to enable them gain visibility in
                the digital space.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={hostingImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">E-COMMERCE</p>
              <p className="mb-0">
                E-commerce website is no longer limited to just adding shopping
                cart! Now developing an ideal e-commerce website seeks
                integrated front and back end support for numerous reasons.
                These include facilitating your employees to track orders, gain
                information about customers and easy update on every page of
                website.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={ecommerceImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">DIGITAL MARKETING</p>
              <p className="mb-0">
                Our IT consultations service, Digital marketing involves
                marketing to consumers through digital channels, including
                websites, mobile devices, and social media platforms.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={digitalImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">GRAPHICS DESIGN</p>
              <p className="mb-0">
                Our IT consultations service provides design of different types
                like Brands Identity and Logo Design,Packaging Design, Web And
                Mobile Design, Layout and Print Design etc.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={graphicsImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">ERP</p>
              <p className="mb-0">
                Our IT consultations service provides expert guidance and
                support for businesses and individuals looking to optimize their
                technology solutions.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={erpImg} alt="..." />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between border rounded py-3 mb-4">
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p className="text-muted fw-bold">IT CONSULTATIONS</p>
              <p className="mb-0">
                Our IT consultations service provides expert guidance and
                support for businesses and individuals looking to optimize their
                technology solutions.
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 order-first d-none">
            <div className="about-image my-5">
              <img height={250} width="100%" src={itImg} alt="..." />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
